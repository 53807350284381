* {
  margin: 0;
  padding: 0;
}

html,
body {
  box-sizing: border-box;
  font-size: 10px;
  overflow-x: hidden;
}

.header {
  display: flex;
  justify-content: center;
  background-color: rgb(224, 224, 224);
}

.inner-header {
  display: flex;
  flex-direction: row;
  width: 1200px;
  align-items: center;
  justify-content: space-between;
  padding: 12px;
}

.links {
  list-style-type: none;
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.links * {
  font-size: 18px;
  color: black;
  text-decoration: none;
}

.links *:hover {
  text-decoration: underline;
}
.slider {
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 80vh; /* Tam ekran yüksekliği */
}

.slider-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 1s ease-in-out; /* Kaydırma efekti */
  transform: translateX(100%); /* Resimler başlangıçta ekranın dışında */
}


.slide-in {
  transform: translateX(0); /* Yeni resim ekrana gelir */
}

.slide-out {
  transform: translateX(-100%); /* Mevcut resim ekranın soluna kayar */
}
.slider-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 8px solid #141b16;
  padding: 72px;
}

.slider-text > h1 {
  color: #000000;
  font-size: 56px;
  font-weight: bolder;
}

.slider-text > h4 {
  color: #070707;
  font-size: 56px;
  font-weight: 100;
  margin-bottom: 32px;
}

.slider-text > a {
  background-color: #ffffff;
  color: #141b16;
  border: 4px solid #000000;
  outline: 0;
  margin-bottom: 16px;
  font-size: 16px;
  width: 196px;
  padding: 12px 0;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
}
.slider-text > a:hover {
  box-shadow: 3px 3px white;
}
.about {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgb(238, 238, 238);
  gap: 48px;
  padding: 64px 0;
}

.about > h1 {
  font-size: 48px;
  text-align: center;
}

.about-text {
  font-size: 20px;
  background-color: white;
  max-width: 60%;
  position: relative;
  padding: 32px 36px;
}

.about-text::before {
  content: '';
  background-image: url('https://www.pngall.com/wp-content/uploads/4/Quotation-Symbol-PNG.png');
  background-size: 100% 100%;
  height: 55px;
  width: 85px;
  opacity: 0.1;
  display: inline-block;
  position: absolute;
  left: 0px;
  top: 0px;
}

.card-cont {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 20px;
  font-family: 'Calibri', sans-serif;
}
.card {
  position: relative;
  height: 400px;
  width: 400px;
  margin: 10px 0;
  transition: ease all 2.3s;
  perspective: 1200px;
}
.card:hover .cover {
  transform: rotateX(0deg) rotateY(-180deg);
}
.card:hover .cover:before {
  transform: translateZ(30px);
}
.card:hover .cover:after {
  background-color: black;
}
.card:hover .cover h1 {
  transform: translateZ(100px);
}
.card:hover .cover .price {
  transform: translateZ(60px);
}
.card:hover .cover a {
  transform: translateZ(-60px) rotatey(-180deg);
}
.card .cover {
  position: absolute;
  height: 100%;
  width: 100%;
  transform-style: preserve-3d;
  transition: ease all 2.3s;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}
.card .cover:before {
  content: '';
  position: absolute;
  border: 5px solid rgba(255, 255, 255, 0.5);
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.3);
  top: 20px;
  left: 20px;
  right: 20px;
  bottom: 20px;
  z-index: 2;
  transition: ease all 2.3s;
  transform-style: preserve-3d;
  transform: translateZ(0px);
}
.card .cover:after {
  content: '';
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 2;
  transition: ease all 1.3s;
  background: rgba(0, 0, 0, 0.4);
}
.card .cover.item-a {
  background-image: url('https://www.pixelstalk.net/wp-content/uploads/2016/10/Beautiful-Building-Wallpaper-HD.jpg');
}
.card .cover.item-b {
  background-image: url('https://www.debtnegotiators.com.au/wp-content/uploads/how-to-budget-in-5-easy-steps.jpg');
}
.card .cover.item-c {
  background-image: url('https://images.unsplash.com/photo-1634474588578-7f0565a1cea5?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTV8fG1hbmFnZW1lbnR8ZW58MHx8MHx8fDA%3D&w=1000&q=80');
}
.card .cover.item-d {
  background-image: url('https://i.pinimg.com/originals/18/69/1c/18691c19d0a3fea43be48d2b0a002a87.png');
}
.card .cover.item-e {
  background-image: url('https://images.alphacoders.com/487/487138.jpg');
}
.card .cover.item-f {
  background-image: url('https://www.pixelstalk.net/wp-content/uploads/2016/10/Backgrounds-Cleaning-HD-Download.jpg');
}
.card .cover.item-g {
  background-image: url('https://e1.pxfuel.com/desktop-wallpaper/816/882/desktop-wallpaper-security-guard-uniform-security-guard.jpg');
}
.card .cover.item-h {
  background-image: url('https://wallpapercave.com/wp/wp2638641.jpg');
}
.card .cover h1 {
  font-weight: 600;
  position: absolute;
  bottom: 55px;
  left: 50px;
  color: white;
  transform-style: preserve-3d;
  transition: ease all 2.3s;
  z-index: 3;
  font-size: 3em;
  transform: translateZ(0px);
}
.card .cover .price {
  font-weight: 200;
  position: absolute;
  top: 55px;
  right: 50px;
  color: white;
  transform-style: preserve-3d;
  transition: ease all 2.3s;
  z-index: 4;
  font-size: 2em;
  transform: translateZ(0px);
}
.card .card-back {
  position: absolute;
  height: 100%;
  width: 100%;
  background: #0b0f08;
  transform-style: preserve-3d;
  transition: ease all 2.3s;
  transform: translateZ(-1px);
  display: flex;
  align-items: center;
  justify-content: center;
}
.card .card-back p {
  transform-style: preserve-3d;
  transition: ease transform 2.3s, ease background 0.5s;
  transform: translateZ(-1px) rotatey(-180deg);
  background: transparent;
  /*border: 1px solid white;*/
  font-weight: 200;
  font-size: 1.3em;
  color: white;
  padding: 14px 32px;
  outline: none;
  text-decoration: none;
  font-size: 20px;
}
.card .card-back a:hover {
  background-color: white;
  color: #0b0f08;
}

.card-back {
  color: white;
}

.services {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgb(250, 250, 250);
  gap: 48px;
  padding: 64px 0;
}

.services > h1 {
  font-size: 48px;
  text-align: center;
}

.footer {
  background-color: rgb(224, 224, 224);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 36px 0;
}

.inner-footer {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
}

.left-footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 32px;
}

.left-footer > p {
  font-size: 17px;
  width: 172px;
  line-height: 28px;
  text-align: center;
}

.left-footer a {
  color: black;
  font-size: 12px;
}

.right-footer {
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding-left: 64px;
}

.right-footer p {
  display: flex;
  flex-direction: column;
  font-size: 16px;
}

.services-page {
  padding-bottom: 64px;
}

.services-page > h1 {
  font-size: 42px;
  text-align: center;
  padding: 64px 0 24px 0;
}

.button-container > * {
  border: 1px solid black;
  font-size: 18px;
  text-decoration: none;
  color: black;
  padding: 16px;
}

.button-container > *:hover {
  color: white;
  background-color: black;
}

.contact {
  background-color: rgb(238, 238, 238);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 64px;
  gap: 32px;
}

.contact > h1 {
  font-size: 42px;
  text-align: center;
  padding: 64px 0 24px 0;
}

iframe {
  border: 0;
}

.contact > p {
  font-size: 18px;
  display: flex;
  flex-direction: column;
}

.contact a {
  color: black;
}

.references {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 64px;
  background-color: rgb(238, 238, 238);
}

.references > h1 {
  font-size: 42px;
  text-align: center;
  padding: 64px 0 24px 0;
}

.ref-container {
  max-width: 1170px;
}

.grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 25px;
}

.grid-item {
  position: relative;
}

.grid-item > img {
  height: 416px;
  width: 374px;
  object-fit: cover;
  filter: brightness(75%);
}

.grid-item > div {
  position: absolute;
  box-shadow: rgb(0, 0, 0, 0.15) 0px 5px 15px 0px;
  background-color: white;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 130px;
  width: 320px;
  gap: 30px;
}

.grid-item > div > h2,
.nongrid-item > div > h2 {
  font-size: 27px;
  text-align: center;
}

.grid-item > div > a,
.nongrid-item > div > a {
  font-size: 16px;
  text-align: center;
  color: #ef4a2b;
}

.nongrid-item {
  position: relative;
  margin-top: 25px;
}

.nongrid-item > img {
  height: 416px;
  object-fit: cover;
  width: 100%;
  filter: brightness(75%);
}

.nongrid-item > div {
  position: absolute;
  box-shadow: rgb(0, 0, 0, 0.15) 0px 5px 15px 0px;
  background-color: white;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 130px;
  width: 1120px;
  gap: 30px;
}

.burger {
  cursor: pointer;
  display: none;
}

.responsive-header {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 50;
  background-color: white;
  width: 100vw;
  height: 100vh;
  padding: 64px 16px;
}

.active {
  display: block;
}

.responsive-header > .links {
  display: flex;
  flex-direction: column;
}

.responsive-header > .links * {
  text-decoration: underline;
}

.close {
  font-size: 48px;
  position: absolute;
  top: 0;
  right: 60px;
  z-index: 99;
  cursor: pointer;
}

.contact > iframe {
  width: 760px;
  height: 500px;
}

@media screen and (max-width: 1000px) {
  .inner-header .links li:not(:last-child) {
    display: none;
  }
  .burger {
    display: block;
  }
  .inner-footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 36px;
  }
  .right-footer {
    padding-left: 0;
  }
}
.phone-number {
  margin-bottom: 10px;
}
@media screen and (max-width: 1200px) {
  .grid-container {
    display: flex;
    flex-direction: column;
    width: 374px;
  }

  .ref-container {
    width: 374px;
  }

  .grid-item {
    width: 374px;
  }
  .nongrid-item > div {
    width: 320px;
  }
}

@media screen and (max-width: 800px) {
  iframe {
    width: 500px;
  }
}
