@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

body {
    font-family: 'Roboto', sans-serif; /* Daha modern ve okunabilir bir font */
}

.slider {
    text-align: center;
    padding: 20px;
}

.slider-text h1,
.slider-text h4 {
    margin: 10px 0;
    font-size: 24px;
    color: #333; /* Daha yumuşak bir siyah tonu */
}

.info-link,
.modal-button {
    display: inline-block;
    margin: 10px;
    padding: 10px 20px;
    font-size: 25px;
    text-decoration: none;
    color: #000000; /* Butonların metin rengi */
    background-color: #ffffff;
    border: 4px solid #090120; /* Butonların çerçeve rengi */
    border-radius: 5px;
    transition: background-color 0.3s ease, color 0.3s ease;
    cursor: pointer;
}

.info-link:hover,
.modal-button:hover {
    background-color: #007bff; /* Arka plan rengi hoverda değişir */
    color: #070707;
}

.modal {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-color: rgba(0, 0, 0, 0.6);
}

.modal-content {
    background-color: #fefefe;
    padding: 20px;
    width: 90%; /* Mobilde daha iyi görünmesi için genişlik ayarı */
    max-width: 500px; /* Daha büyük ekranlar için max genişlik */
    max-height: 80vh; /* Mobilde daha iyi uyum için yüksekliği kontrol edin */
    border-radius: 15px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 10px;
    border-bottom: 1px solid #ddd;
}

.modal-header h2 {
    font-size: 20px;
    color: #444; /* Başlık metin rengi daha yumuşak */
}

.search-input {
    margin: 15px 0;
    padding: 10px;
    width: 100%;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 16px;
    color: #555; /* Giriş metin rengi */
}

.close {
    color: #777; /* Kapatma butonu rengi daha yumuşak */
    font-size: 24px;
    font-weight: bold;
    cursor: pointer;
    transition: color 0.3s;
}

.close:hover,
.close:focus {
    color: #000;
}

.item-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
    flex-grow: 1;
    overflow-y: auto;
    max-height: 400px; /* Liste boyutunu kontrol edin */
}

.item-list li {
    padding: 10px;
    display: flex;
    align-items: center;
    cursor: pointer;
    border-bottom: 1px solid #eee;
    transition: background-color 0.3s ease, transform 0.2s ease;
    font-size: 16px;
    color: #555; /* Liste elemanlarının metin rengi */
}

.item-list li:hover {
    background-color: #f0f0f0;
    transform: scale(1.01); /* Hafif büyüme efekti */
}

.item-list input[type='radio'] {
    margin-right: 10px;
}

.footerCustom {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 0;
    margin-top: 20px;
    font-size: 14px;
    color: #666; /* Footer metin rengi */
    border-top: 1px solid #ddd; /* Üstünde bir çizgi */
    position: sticky;
    bottom: 0;
    background-color: #fefefe;
    width: 100%;
}

.logo {
    width: 20px; /* Logonun genişliğini ayarlayın */
    height: auto;
    margin-right: 5px; /* Logonun yanındaki boşluk */
}

.footerCustom span {
    font-size: 14px;
    color: #666;
}